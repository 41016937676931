import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { ORDER_INVOICE } from '../../../../data/enums/Route';
import { clone, filterSelectedListDetails } from '../../../../utils/arrayProcessor';
import { ORDER_TABS } from '../config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { setActiveOrder } from '../../../../data/services/salesOrder';
import { isError } from '../../../common/HelperFunctions';
import { ERROR } from '../../../../data/enums/ErrorMessage';
import { getPermissionToViewStockInfo } from './config';
import { TableHeader } from './tableConfig';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  serverResponseWaiting: PropTypes.bool,
  stateSetter: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  // handleActiveTabListChange: PropTypes.func.isRequired,
  onCallRowClick: PropTypes.func.isRequired,
  handlePassiveTabListChange: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  onPageChange: PropTypes.func,
  onHeaderClick: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  onPageChange: () => null,
  onHeaderClick: () => null,
};

class Confirmed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCall: {
        index: -1,
        value: '',
        sellerId: '',
      },
      stockStatusInfo: false,
    };
    this.enableStatusView = getPermissionToViewStockInfo();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    const { onHeaderClick } = this.props;
    this.resetActiveCall();
    TableHeader({ onHeaderClick, dynamicHeader: true });
  }

  resetActiveCall = () => {
    const { activeCall } = this.state;
    activeCall.index = '';
    activeCall.value = '';
    activeCall.sellerId = '';
    this.setState({ activeCall });
    if (this.enableStatusView) {
      this.setState({ stockStatusInfo: true });
    }
  };

  filterOrderIds = orderObj => {
    const orderIds = orderObj.Lines?.map(item => item.id) || [];
    return orderIds;
  };

  handleInvoiceClick = async selectedOrders => {
    debugger
    const { activeCall } = this.state;
    const { data } = this.props;
    const activeOrder = data.list[activeCall.index] || {};
    const { Orders = [] } = activeOrder;

    const ids = Orders.map(item => this.filterOrderIds(item)).flat();
    if (activeOrder.callOrderValue && activeOrder.callOrderValue.topUpDiscount) {
      await this.validatePromotion(activeOrder.id, ids).then(valid => {
        if (valid) this.directToInvoice(activeOrder, ids);
      });
    } else this.directToInvoice(activeOrder, ids);
  };

  prepareInvoiceData = (activeCall, selectedOrders) => {
    const activeOrdersReplica = clone(activeCall.Orders || []);
    const selectedOrdersDetailList = activeOrdersReplica
      .reduce((orders, promotionItem) => {
        const checkedOrders = filterSelectedListDetails(clone(promotionItem.Lines || []), selectedOrders);
        if (checkedOrders.length > 0) {
          orders.push(checkedOrders);
        }
        return orders;
      }, [])
      .flat();
    const { Orders, ...activeCallReplica } = activeCall;
    activeCallReplica.selectedOrders = selectedOrdersDetailList || [];
    activeCallReplica.selectedIds = selectedOrders || [];

    return activeCallReplica;
  };

  /** validating top up promotion * */
  validatePromotion = async (callId, orderIds) => {
    const { getPromotionValidation, displayAlert } = this.props;

    return new Promise((resolve, reject) => {
      getPromotionValidation(
        { orderIds, callId },
        {
          handleSuccess: response => {
            if (response.data.validateInvoice === 'true') {
              resolve(true);
            } else if (isError(response)) {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, response.errors[0].message);
              resolve(false);
            } else {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, ERROR);
              resolve(false);
            }
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
            resolve(false);
          },
        },
      );
    });
  };

  directToInvoice = (activeOrder, selectedOrders) => {
    const { history } = this.props;
    const preparedOrder = this.prepareInvoiceData(activeOrder, selectedOrders);
    /** test purpose */
    localStorage.setItem('activeOrder', JSON.stringify(preparedOrder));
    /** test purpose */
    setActiveOrder(preparedOrder).then(() => {
      history.push(`/${ORDER_INVOICE}`);
    });
  };

  handleRowClick = (callId, refId, sellerId) => {
    const { onCallRowClick, data } = this.props;
    const { activeCall } = this.state;
    if (refId !== activeCall.value) {
      const indexInDataList = data.list.findIndex(
        element => element.Distributor.id === sellerId && element.id === callId && element.refId === refId,
      );
      activeCall.value = refId;
      activeCall.index = indexInDataList;
      this.setState({ activeCall });

      onCallRowClick(callId, sellerId, refId );
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handlePageChange = pagination => {
    const { onPageChange } = this.props;
    onPageChange(ORDER_TABS.CONFIRMED, pagination);
  };

  render() {
    const { data, stateSetter, serverResponseWaiting, handlePassiveTabListChange, onHeaderClick } = this.props;

    const { activeCall, stockStatusInfo } = this.state;

    return (
        <View
          data={data}
          stateSetter={stateSetter}
          loading={serverResponseWaiting}
          onRowClick={this.handleRowClick}
          activeCallIndex={activeCall.index}
          onPageChange={this.handlePageChange}
          onInvoiceClick={this.handleInvoiceClick}
          handlePassiveTabListChange={handlePassiveTabListChange}
          onHeaderClick={onHeaderClick}
          stockStatusInfo={stockStatusInfo}
        />
    );
  }
}

Confirmed.propTypes = propTypes;

Confirmed.defaultProps = defaultProps;

export default withAlert()(Confirmed);
